export interface Deanery {
    id: number;
    name: string;
}

export const deaneries: Deanery[] = [
    { id: 21, name: "Archidiecezja białostocka" },
    { id: 4, name: "Diecezja bielsko-żywiecka" },
    { id: 42, name: "Diecezja bydgoska" },
    { id: 5, name: "Archidiecezja częstochowska" },
    { id: 6, name: "Diecezja drohiczyńska" },
    { id: 7, name: "Diecezja elbląska" },
    { id: 8, name: "Diecezja ełcka" },
    { id: 9, name: "Archidiecezja gdańska" },
    { id: 10, name: "Archidiecezja gnieźnieńska" },
    { id: 1, name: "Diecezja gliwicka" },
    { id: 2, name: "Archidiecezja katowicka" },
    { id: 11, name: "Diecezja kaliska" },
    { id: 12, name: "Diecezja kielecka" },
    { id: 13, name: "Diecezja koszalińsko-kołobrzeska" },
    { id: 14, name: "Archidiecezja krakowska" },
    { id: 15, name: "Diecezja legnicka" },
    { id: 16, name: "Archidiecezja lubelska" },
    { id: 18, name: "Diecezja łowicka" },
    { id: 19, name: "Archidiecezja łódzka" },
    { id: 3, name: "Diecezja opolska" },
    { id: 46, name: "Obrządek ormiański" },
    { id: 23, name: "Diecezja pelplińska" },
    { id: 22, name: "Diecezja płocka" },
    { id: 24, name: "Archidiecezja poznańska" },
    { id: 25, name: "Archidiecezja przemyska" },
    { id: 27, name: "Diecezja radomska" },
    { id: 28, name: "Diecezja rzeszowska" },
    { id: 29, name: "Diecezja sandomierska" },
    { id: 30, name: "Diecezja siedlecka" },
    { id: 31, name: "Diecezja sosnowiecka" },
    { id: 32, name: "Archidiecezja szczecińsko-kamieńska" },
    { id: 43, name: "Diecezja świdnicka" },
    { id: 33, name: "Diecezja tarnowska" },
    { id: 34, name: "Diecezja toruńska" },
    { id: 35, name: "Diecezja włocławska" },
    { id: 20, name: "Archidiecezja warmińska" },
    { id: 36, name: "Archidiecezja warszawska" },
    { id: 37, name: "Ordynariat Polowy Wojska Polskiego" },
    { id: 38, name: "Diecezja warszawsko-praska" },
    { id: 39, name: "Archidiecezja wrocławska" },
    { id: 40, name: "Diecezja zamojsko-lubaczowska" },
    { id: 41, name: "Diecezja zielonogórsko-gorzowska" },
];