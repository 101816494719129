import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import ReactGA from "react-ga4";

export default function Regulamin() {

    const location = useLocation();

    useEffect(() => {
        ReactGA.send(location.pathname + location.search);
    }, [location]);

    return(
        <div>
            <p style={{textAlign: "center"}}>Pielęgnujmy pamięć o zmarłych z należnym szacunkiem.</p>
        </div>
    )
}