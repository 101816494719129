
import React, {useEffect, useState} from "react";
import BookList from "../components/BookList";
import {useKeycloak} from "@react-keycloak/web";
import LoginWelcomeView from "../components/LoginWelcomeView";
import {useLocation} from "react-router-dom";
import ReactGA from "react-ga4";

export default function HomePage() {
    const { keycloak, initialized } = useKeycloak();
    const location = useLocation();

    useEffect(() => {
        ReactGA.send(location.pathname + location.search);
    }, [location]);

    return (
        <>
            {(initialized && !keycloak.authenticated) && <BookList/>}
            {(initialized && keycloak.authenticated) && <LoginWelcomeView/>}
        </>
    );
}
