import {Button, Checkbox, Container, FormControlLabel, TextField} from "@mui/material";
import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import './NewComment.scss'
import axios from "axios";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import MessageContext from "../MessageContext";
import ReactGA from "react-ga4";

export default function NewComment() {
    const {url} = useParams() as { url: string }
    const [book, setBook] = useState({photo: "", name: "", content: "", comments: []})
    const navigate = useNavigate()

    const location = useLocation();

    const [fileName, setFileName] = useState("")

    const [title, setTitle] = useState("")
    const [content, setContent] = useState("")
    const [photo, setPhoto] = useState("")
    const [signature, setSignature] = useState("")
    const [contactData, setContactData] = useState("")
    const setMessage = useContext(MessageContext);

    useEffect(() => {
        if (book.name) {
            document.title = book.name + ' - Dodaj wpis - sp.katolicki.pl';
            ReactGA.send(location.pathname + location.search);
        }
    }, [book]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_MEMENTO_SERVER_URL + "api/v1/books/" + url).then(
            d => setBook(d.data)
        ).catch(
            d => navigate("/404")
        )
    }, [url]);

    function getBase64(file: any) {
        var reader = new FileReader() as any;
        reader.readAsDataURL(file);
        reader.onload = function () {
            setPhoto(reader.result)
        };
        reader.onerror = function (error : any) {
            setPhoto("")
        };
    }

    function send(e: any) {
        e.preventDefault();
        axios.post(process.env.REACT_APP_MEMENTO_SERVER_URL + "api/v1/books/" + url + "/comments",
            {
                "title": title,
                "content": content,
                "photo": photo,
                "signature": signature,
                "contactData": contactData
            }).then(d => {
            setMessage("Twój wpis został dodany do kolejki oczekujących na publikację.")
            navigate(-1)
        })
            .catch(d => setMessage("Błąd podczas tworzenia wpisu"))
    }

    function onChangeFile(e: ChangeEvent<HTMLInputElement>) {
        if (!e.target.files) {
            return;
        }
        const data = e.target.files[0];
        getBase64(data)
        const {name} = data;
        setFileName(name)
    }

    return (
        <>
            <div className="PageHeader">
                <Container>
                    <h2>Dodawanie wpisu do księgi - {book.name}</h2>
                </Container>
            </div>
            <Container className="NewCommentWrapper">
                <form onSubmit={send}>
                    <p>*1. Proszę wpisać tytuł swojego wpisu:</p>
                    <TextField fullWidth label="Tytuł wpisu" variant="outlined" required size="small"
                               onChange={e => setTitle(e.target.value)}/>
                    <p>*2. Proszę wpisać treść swojego wpisu:</p>
                    <TextField fullWidth label="Treść wpisu" variant="outlined" required size="small" multiline
                               rows={8} onChange={e => setContent(e.target.value)}/>
                    <p>3. Proszę dodać fotografię do swojego wpisu (opcjonalnie):</p>
                    <Button variant="contained" component="label">
                        {!fileName && <>Wybierz plik z dysku</>}
                        {fileName && <>{fileName}</>}
                        <input
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            hidden
                            onChange={onChangeFile}
                        /></Button>
                    <p>*4. Proszę wpisać swoje imię i nazwisko lub inny podpis:</p>
                    <TextField fullWidth label="Podpis" variant="outlined" required size="small"
                               onChange={e => setSignature(e.target.value)}/>
                    <p>5. Proszę podać jakieś dane kontaktowe (opcjonalnie)</p>
                    <TextField fullWidth label="Dane kontaktowe" variant="outlined" size="small"
                               onChange={e => setContactData(e.target.value)}/>
                    <FormControlLabel required control={<Checkbox/>}
                                      label={<>potwierdzam, że zapoznałem się z <a href="/regulamin">regulaminem</a></>}/>
                    <FormControlLabel required control={<Checkbox/>}
                                      label="wyrażam zgodę na publikację mojego wpisu na licencji Creative Commons CC0 (domena publiczna)"/>
                    <FormControlLabel required={!!photo} control={<Checkbox/>}
                                      label="oświadczam, że mam prawo do dysponowania fotografią "/>
                    <p>6. Proszę zapisać wpis (po zapisaniu wpis będzie niewidoczny do momentu udostępnienia go przez
                        administratora księgi)</p>
                    <Button variant="outlined" size="large" type="submit">Zapisz</Button>
                </form>
            </Container>
        </>
    )
}