import {Button, Container, Divider, Grid, LinearProgress, Stack} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import './LoginPage.scss';
import axios from "axios";
import MessageContext from "../MessageContext";
import {useNavigate} from "react-router-dom";
import {ProfileContext} from "../ProfileContext";
import {useKeycloak} from "@react-keycloak/web";
import BookCommentToAccept from "./BookCommentToAccept";

export default function LoginWelcomeView() {
    const router = useNavigate()
    const {keycloak, initialized} = useKeycloak();

    const [comments, setComments] = useState([])
    const [loading, setLoading] = useState(true)
    const setMessage = useContext(MessageContext);
    const profile = useContext(ProfileContext);

    function reload() {
        setLoading(true);
        axios.get(process.env.REACT_APP_MEMENTO_SERVER_URL + "api/v1/comments", {
            headers: {
                'Authorization': 'Bearer ' + keycloak.token,
            }
        }).then(d => {
            setComments(d.data)
            setLoading(false)
        })
            .catch(d => setMessage("Błąd podczas ładowania wpisów do akceptacji"));
    }

    useEffect(() => {
        reload();
    }, []);

    return (
        <>
            <div className="PageHeader">
                <Container>
                    <h2>Jesteś zalogowany jako {profile.attributes.displayed_name}</h2>
                </Container>
            </div>
            <Container className="LoginPageWrapper">
                <Grid container sx={{mb: 2}}>
                    <Grid item xs={12} md={10}>
                        <h2>{profile.attributes.displayed_name}</h2>
                        <h3>adres<br/>{profile.attributes.displayed_address}</h3>
                        <p>Zobacz swoją parafię na stronie <a
                            href="https://schematyzm.katolicki.pl">schematyzm.katolicki.pl</a></p>
                    </Grid>
                    <Grid item xs={12} md={2} alignItems="right" alignContent="right">
                        <Stack spacing={2}>
                            <Button variant="outlined" fullWidth onClick={() => keycloak.logout()}>
                                Wyloguj
                            </Button>
                            <Button variant="outlined" fullWidth onClick={() => router('/moje-ksiegi')}>
                                Zobacz księgi
                            </Button>
                            <Button variant="outlined" fullWidth onClick={() => router('/dodaj-ksiege')}>
                                Dodaj księgę
                            </Button>
                            <Button variant="outlined" fullWidth onClick={() => window.location.href ='https://ekuria.pl'}>
                                Panel ekuria.pl
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
                <Divider color="#004AAD"/>
                <Grid container sx={{mt: 2}}>
                    <h1>Wpisy czekające na akceptację</h1>
                    {!loading && comments.map((c:any) => {
                        return (<BookCommentToAccept key={c.id} comment={c} reload={reload}/>)
                    })}
                    {
                        loading && <LinearProgress/>
                    }

                </Grid>
                {/*<div>*/}
                {/*    <Pagination count={10} color="primary" size="large"/>*/}
                {/*</div>*/}
            </Container>
        </>
    )
}