import './BookList.scss'
import {
    Box,
    Container,
    FormControl,
    Grid,
    InputLabel,
    LinearProgress,
    MenuItem,
    Pagination,
    Select,
    Stack,
    TextField
} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import MessageContext from "../MessageContext";
import {useKeycloak} from "@react-keycloak/web";
import BookListElement from "./BookListElement";
import {useLocation} from "react-router-dom";
import ReactGA from "react-ga4";


export default function BookList() {

    const [deaneryId, setDeaneryId] = useState<number | null>(null)
    const [query, setQuery] = useState(null)
    const [sortOrder, setSortOrder] = useState({field: 'newestComment', 'order': 'DESC', label: 'Od najnowszego wpisu'})
    const [loading, setLoading] = useState(true)
    const [pageNumber, setPageNumber] = useState(1)
    const [bookList, setBookList] = useState({count: 0, books: [] as any[]})
    const setMessage = useContext(MessageContext);
    const {keycloak, initialized} = useKeycloak();

    const location = useLocation();

    useEffect(() => {
        ReactGA.send(location.pathname + location.search);
    }, [location]);

    useEffect(() => {
        setLoading(true)
        const searchParams = new URLSearchParams();
        searchParams.append("offset", ((pageNumber - 1) * 10).toString())
        if (query) {
            searchParams.append("query", query)
        }
        if (deaneryId) {
            searchParams.append("deaneryId", deaneryId.toString())
        }
        searchParams.append("my", "1")
        searchParams.append("sortBy", sortOrder.field)
        searchParams.append("sortOrder", sortOrder.order)
        axios.get(process.env.REACT_APP_MEMENTO_SERVER_URL + "api/v1/books?" + searchParams.toString(), {
            headers: {
                'Authorization': 'Bearer ' + keycloak.token,
            }
        }).then(d => {
            setBookList(d.data)
            setLoading(false)
        }).catch(d => {
            setMessage("Błąd podczas ładowanie ksiąg")
            setLoading(false)
        })
    }, [query, deaneryId, sortOrder, pageNumber]);

    return (
        <div className="BookList">
            <div className="PageHeader">
                <Container>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item md={4} xs={12}><h2>Moje księgi</h2></Grid>
                        <Grid item md={2} xs={0}>

                        </Grid>

                        <Grid item md={2} xs={12}><TextField variant="filled" size="small" className="mementoForm"
                                                             label="Wyszukaj" fullWidth
                                                             onKeyUp={(e : any) => {
                                                                 if (e.key === 'Enter') {
                                                                 setQuery(e.target.value)
                                                             }
                                                             }}
                                                             onBlur={e => {
                            setQuery(e.target.value as any)
                        }}/></Grid>
                        <Grid item md={2} xs={6}></Grid>
                        <Grid item md={2} xs={6}><FormControl variant="filled" fullWidth size="small">
                            <InputLabel>Sortowanie</InputLabel>
                            <Select placeholder="Wybierz" onChange={e => {
                                setSortOrder(e.target.value as any)
                            }}>
                                <MenuItem value={{field: 'newestComment', order: 'DESC', label: 'Od najnowszego wpisu'} as any}>Od najnowszego wpisu</MenuItem>
                                <MenuItem value={{field: 'newestComment', order: 'ASC', label: 'Od najstarszego wpisu'} as any}>Od najstarszego wpisu</MenuItem>
                                <MenuItem value={{field: 'createdAt', order: 'DESC', label: 'Od najnowszej księgi'} as any}>Od najnowszej księgi</MenuItem>
                                <MenuItem value={{field: 'createdAt', order: 'ASC', label: 'Od najstarszej księgi'} as any}>Od najstarszej księgi</MenuItem>
                                <MenuItem value={{field: 'lastName', order: 'ASC', label: 'Po nazwisku rosnąco'} as any}>Po
                                    nazwisku rosnąco</MenuItem>
                                <MenuItem value={{field: 'lastName', order: 'DESC', label: 'Po nazwisku malejąco'} as any}>Po
                                    nazwisku malejąco</MenuItem>
                            </Select>
                        </FormControl></Grid>
                    </Grid>
                </Container>
            </div>
            <Container>
                <Stack className="BookListElements" direction="column" spacing={2}>
                    {loading && <LinearProgress/>}
                    {!loading && bookList.books.length > 0 && bookList.books.map(b => {
                        return (<BookListElement book={b} key={b.id}></BookListElement>)
                    })}
                    {!loading && bookList.books.length == 0 && <h1>Brak takich ksiąg</h1>}
                </Stack>
                <Box>
                    <Pagination count={Math.floor(bookList.count / 10) + 1} onChange={(e, n) => setPageNumber(n)}
                                color="primary" size="large"/>
                </Box>
            </Container>
        </div>
    )
}