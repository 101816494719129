import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Keycloak from "keycloak-js";
import {ReactKeycloakProvider} from "@react-keycloak/web";

import ReactGA from 'react-ga4';


const keycloak = new Keycloak({
    url: `${process.env.REACT_APP_KEYCLOAK_URL}`,
    realm: `${process.env.REACT_APP_KEYCLOAK_REALM}`,
    clientId: `${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

ReactGA.initialize('G-L3Y5KNEX8W'); // Replace with your actual tracking ID

root.render(
    <ReactKeycloakProvider authClient={keycloak}>
        <React.StrictMode>
            <App/>
        </React.StrictMode>
    </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
